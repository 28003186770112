import "styles/pages/contact.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

const ContactConfirmation = () => {
  const title = "Podziękowanie"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="contact-confirmation">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="contact-confirmation__wrapper">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <PageSubHeader title="Dziękujemy za wiadomość!" />
                <p>
                  Twoja wiadomość już do nas dotarła. Skontaktujemy się
                  najszybciej jak to możliwe. Do usłyszenia.
                </p>
                <Button type="link" to="/">
                  Strona główna
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactConfirmation
